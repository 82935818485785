
import { Component, Vue, Watch, } from 'vue-property-decorator'
import { mapState, mapGetters, } from 'vuex'
import Avatar from 'vue-avatar'
import OverlayBackground from "@/components/OverlayBackground/Index.vue"
import CoreContainer from '@/layouts/CoreContainer/Index.vue'
import ConfirmDialog from '@/components/ConfirmDialog/Index.vue'
import TableEmptyState from '@/components/TableEmptyState/Index.vue'
import ChipHackmetrix from '@/components/Chip/Index.vue'
import { TemplateGetInterface, } from '@/store/modules/recomendations/recomendationModule'

@Component({
  components: {
    CoreContainer,
    ConfirmDialog,
    ChipHackmetrix,
    Avatar,
    TableEmptyState,OverlayBackground
  },
  computed: {
    ...mapState('RecomendationModule', ['RecomendationState',]),
    ...mapGetters('PolimorficModule', ['getFramework',]),
    ...mapGetters('RecomendationModule', ['getTemplatesPages',]),
  },
})
export default class TemplatesComponent extends Vue {
  private loading = false;
  private confirm = false
  private templates = []
  private deleteTo: any[] = []
  private Template = this.$store.state.RecomendationModule.RecomendationState
  private filter_framework = false
  private filter_type = false
  private filter_go = []
  private role = this.$store.state.AuthModule.AuthState.role
  private page = 1
  private searchText = ''

  mounted (): void {
    this.$mixpanel.track('Seccion Plantillas')
    this.$store.dispatch('PolimorficModule/getFrameworks')
    this.getTemplates()
  }

  private closeDialogFolder () {
    this.confirm = false
  }

  private getTemplates () {
    if (this.role.includes ('Customer Success')) {
      return
    }
    this.filter_framework = false
    this.filter_type = false
    this.loading = true

    const sendTemplatesInterface: TemplateGetInterface = {
      page: this.page,
      query: JSON.stringify(this.filter_go),
      title_contains: this.searchText,
    }

    this.$store
      .dispatch('RecomendationModule/getTemplates', sendTemplatesInterface)
      .then(() => {
        this.templates = this.Template.templates
        this.$store.commit('SearchModule/SET_SEARCH_RESULTS', this.templates)
        this.loading = false
      })
      .catch(() => {
        this.loading = false
      })
  }

  private goToTemplate (element: any) {
    const route = element ? `/app/templates/${element.id}/edit` : '/app/templates/create'
    this.$router.push(route)
  }

  private deleteT () {
    this.$store.dispatch('NotificactionsModule/OpenNotification', {
      message: 'Porfavor espere, Eliminando plantillas',
    })
    this.confirm = false

    this.$store.dispatch('RecomendationModule/deleteTemplate', this.deleteTo).then(() => {
      this.deleteTo = []
      this.getTemplates()
      this.$store.dispatch('NotificactionsModule/OpenNotification', {
        message: 'Éxito, Eliminado correctamente',
      })
    })
  }

  private deleteTemplate (data: any) {
    this.deleteTo.push(data.id)
    this.confirm = true
  }

  private openFilter (value: number) {
    switch (value) {
    case 0:
      this.filter_framework = !this.filter_framework
      this.filter_type = false
      break
    case 1:
      this.filter_type = !this.filter_type
      this.filter_framework = false
      break

    default:
      break
    }
  }

  private deleteFilters () {
    this.searchText = ''
    this.filter_go = []
    this.getTemplates()
  }

  @Watch('page')
  private getOtherPage () {
    this.getTemplates()
  }
}
