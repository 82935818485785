var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.loading)?_c('OverlayBackground'):_vm._e(),_c('vs-row',{attrs:{"w":12}},[_c('vs-col',{attrs:{"w":8}},[_c('h3',{staticClass:"title"},[_vm._v("Plantillas")])]),_c('vs-col',{attrs:{"w":4,"align":"right"}},[_c('vs-button',{staticClass:"btn-export",attrs:{"active":"","relief":""},on:{"click":function($event){return _vm.goToTemplate()}}},[_c('md-icon',{staticClass:"icon"},[_vm._v("add_circle_outline")]),_vm._v(" Nueva plantilla ")],1)],1)],1),_c('vs-row',{staticClass:"mt-2 mb-2",attrs:{"w":12}},[_c('div',{staticClass:"filters d-flex"},[_c('div',[_c('vs-input',{staticClass:"searchbar",attrs:{"type":"search","label-placeholder":'Buscar plantilla'},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.getTemplates.apply(null, arguments)}},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('md-icon',{staticClass:"position-back"},[_vm._v("search")])]},proxy:true}]),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1),_c('div',{staticClass:"filter-framework ml-1",on:{"click":function($event){return _vm.openFilter(0)}}},[_c('div',{staticClass:"filter-button"},[_vm._v("Framework")]),_c('md-icon',[_vm._v("expand_more")])],1),(_vm.filter_framework)?_c('div',{staticClass:"filter-content framework-filter-position"},[_vm._l((_vm.getFramework),function(item){return _c('div',{key:item.id},[_c('md-checkbox',{staticClass:"md-primary",attrs:{"value":item.name},model:{value:(_vm.filter_go),callback:function ($$v) {_vm.filter_go=$$v},expression:"filter_go"}},[_vm._v(_vm._s(item.name))])],1)}),_c('div',{staticClass:"filter-footer d-flex"},[_c('vs-button',{attrs:{"active":"","relief":""},on:{"click":function($event){_vm.getTemplates()
              _vm.page = 1}}},[_vm._v(" Aplicar ")]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.filter_framework = !_vm.filter_framework}}},[_vm._v("Cancelar")])],1)],2):_vm._e(),_c('div',{staticClass:"filter-type ml-1"},[_c('div',{staticClass:"filter-framework",on:{"click":function($event){return _vm.openFilter(1)}}},[_vm._v(" Tipo "),_c('md-icon',[_vm._v("expand_more")])],1),(_vm.filter_type)?_c('div',{staticClass:"filter-content"},[_c('md-checkbox',{staticClass:"md-primary",attrs:{"value":"Evidence"},model:{value:(_vm.filter_go),callback:function ($$v) {_vm.filter_go=$$v},expression:"filter_go"}},[_vm._v("Evidencia")]),_c('md-checkbox',{staticClass:"md-primary",attrs:{"value":"Justification"},model:{value:(_vm.filter_go),callback:function ($$v) {_vm.filter_go=$$v},expression:"filter_go"}},[_vm._v("Justificación")]),_c('md-checkbox',{staticClass:"md-primary",attrs:{"value":"Recommendation"},model:{value:(_vm.filter_go),callback:function ($$v) {_vm.filter_go=$$v},expression:"filter_go"}},[_vm._v("Recomendación")]),_c('div',{staticClass:"filter-footer d-flex"},[_c('vs-button',{attrs:{"active":"","relief":""},on:{"click":function($event){_vm.getTemplates()
                _vm.page = 1}}},[_vm._v(" Aplicar ")]),_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){_vm.filter_type = !_vm.filter_type}}},[_vm._v("Cancelar")])],1)],1):_vm._e()]),(_vm.filter_go.length > 0)?_c('div',{staticClass:"delete-filter ml-2",on:{"click":_vm.deleteFilters}},[_c('span',[_vm._v("Borrar Filtros")])]):_vm._e()])]),_c('div',[[_c('div',[_c('vs-table',{scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('vs-tr',[_c('vs-th',{attrs:{"width":"40%"}},[_vm._v("Titulo")]),_c('vs-th',{attrs:{"align":"left"}},[_vm._v("Normativa")]),_c('vs-th',[_vm._v("Última edición")]),_c('vs-th',{attrs:{"align":"center","width":"10%"}},[_vm._v("Acciones")])],1)]},proxy:true},(_vm.templates.length > 0)?{key:"tbody",fn:function(){return _vm._l((_vm.$store.state.SearchModule.SearchState.searchResults),function(tr){return _c('vs-tr',{key:tr.id},[_c('vs-td',[_vm._v(" "+_vm._s(tr.title)+" ")]),_c('vs-td',{staticClass:"left-padding",attrs:{"align":"left"}},[_c('vs-row',_vm._l((tr.tags),function(tag,index){return _c('ChipHackmetrix',{key:index,staticClass:"margin-chips",attrs:{"size":"default","status":"default","text":tag}})}),1)],1),_c('vs-td',[_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm._f("dateFormat")(tr.updated_at)))]),(tr.author)?_c('vs-tooltip',{scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v(_vm._s(tr.author))]},proxy:true}],null,true)},[_c('avatar',{attrs:{"username":tr.author,"size":22,"customStyle":{
                        'font-weight': 'bold',
                        'font-size': '12px',
                        'margin-left': '10px',
                      },"color":"#fff"}})],1):_vm._e()],1)]),_c('vs-td',{staticClass:"cursor-pointer",attrs:{"align":"center"}},[_c('vs-tooltip',{staticClass:"tooltip-inline",scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v("Editar")]},proxy:true}],null,true)},[_c('md-icon',{staticClass:"position-back mr-1",nativeOn:{"click":function($event){return _vm.goToTemplate(tr)}}},[_vm._v(" create ")])],1),_c('vs-tooltip',{staticClass:"tooltip-inline",scopedSlots:_vm._u([{key:"tooltip",fn:function(){return [_vm._v("Borrar")]},proxy:true}],null,true)},[_c('md-icon',{staticClass:"position-back",nativeOn:{"click":function($event){return _vm.deleteTemplate(tr)}}},[_vm._v(" delete_outline ")])],1)],1)],1)})},proxy:true}:null,(_vm.templates.length > 0)?{key:"footer",fn:function(){return [_c('vs-pagination',{attrs:{"length":_vm.getTemplatesPages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}:null],null,true)})],1)],(_vm.templates.length === 0)?_c('TableEmptyState',{attrs:{"title":"No hay plantillas","text":"Carga nueva plantillas haciendo clic en Agregar plantillas","image":"Plantillas.svg"}}):_vm._e()],2),_c('ConfirmDialog',{attrs:{"title":"¿Estás seguro?","subtitle":"Cuando eliminas una plantilla la acción no se puede revertir","danger":"","action":"Eliminar para siempre","showDialog":_vm.confirm},on:{"action":_vm.deleteT,"close":_vm.closeDialogFolder}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }